export const PLAYER_LOAD_SUCCESS = "PLAYER_LOAD_SUCCESS";
export const PLAYER_LOAD_FAILURE = "PLAYER_LOAD_FAILURE";
export const RATING_GET_SUCCESS = "RATING_GET_SUCCESS";
export const RATING_GET_FAILURE = "RATING_GET_FAILURE";
export const RATE_PLAYER_SUCCESS = "RATE_PLAYER_SUCCESS";
export const RATE_PLAYER_FAILURE = "RATE_PLAYER_FAILURE";
export const TWEET_COMPARISON_SUCCESS = "TWEET_COMPARISON_SUCCESS";
export const TWEET_COMPARISON_FAIL = "TWEET_COMPARISON_FAIL";

export const LEADERBOARD_LOAD_SUCCESS = "LEADERBOARD_LOAD_SUCCESS";
export const LEADERBOARD_LOAD_FAILURE = "LEADERBOARD_LOAD_FAILURE";
